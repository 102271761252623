<template>
    <KCourse loader-class="MBcont" course-id="8" title="Будуарная кукла" :items="items">
        <div class="MBtextcont">
            <h3>1. Замешиваем грунт и краску</h3>
        </div>
                
        <VideoView video-id="dc244a00cf934209a75922519bdc305f"/>

        <div class="MBtextcont">
            <h3>1.2. Второй способ колерования грунта и краски</h3>
            <p>Если у вас нет краски бежевых оттенков, а только охра, синий и красный, то можете попробовать этот способ.</p>
        </div>

        <VideoView video-id="8ebf3eb4fc974958902be88c8a78dec7"/>

        <div class="MBtextcont">
            <h3>2. Наносим грунт на детали на 4 слоя</h3>
        </div>

        <VideoView video-id="aabbe3ddb2b54001a0404fa46710fd6c"/>

        <div class="MBtextcont">
            <h3>3. Шкурим грунт</h3>
        </div>
                
        <VideoView video-id="49999dd259714cecbeaa26a9225c14a0"/>

        <div class="MBtextcont">
            <h3>4. Наносим краску на 2-3 слоя</h3>
            <p>ВАЖНО! После того как нанесли последний слой краски, детали можно трогать только используя перчатки</p>
        </div>          

        <VideoView video-id="71b3d70b10fc464e8f28b448fba556ba"/>

        <div class="MBtextcont">
            <h3>5. Подбираем материалы и цветовую гамму</h3>
        </div>

        <VideoView video-id="fde3095d1613442f98604f39a48e5449"/>


        <div class="MBtextcont">
            <h3 class="nomargin">Итого, что нужно сделать:</h3>
            <ol>
                <li>Замесить грунт и краску</li>
                <li>Нанести грунт на детали на 4 слоя</li>
                <li>Отшкурить</li>
                <li>Нанести краску на 2-3 слоя</li>
                <li>Определиться с цветовой гаммой куклы (цвет глаз + цвет волос + цвет костюма)</li>
                <li><b>Подготовить материалы</b> к следующему занятию:</li>
                <ol type="a">
                    <li>Кисть для растушевки (круглая или плоская №6)</li>
                    <li>Тонкие кисти (натуральный колонок 0 или 00)</li>
                    <li>Плоская кисть со скругленным концом (синтетика №1 или 2)</li>
                    <li>Кисть с коротким плотным ворсом №2 или 3</li>
                    <li>Карандаши акварельные коричневые, красные, зеленые и голубые оттенки (хорошозаточенные)</li>
                    <li>Полировочная губка на вспенненной основе</li>
                    <li>Пастель</li>
                    <li>Перчатки</li>
                    <li>Акриловые краски под цвет глаз + белый цвет</li>
                    <li>Лак-аэрозоль матовый (Mr. Super Clear, Idea Spray)</li>
                    <li>Небольшой кусочек кальки</li>
                </ol>
            </ol>

           <h3>Время выполнения: 6 часов</h3>
        </div>
    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage
        },
        data() {
        return {
            items: chapters
        }
        },
        methods: {
            showImage(src, customClass) {
                this.$swal.fire({
                    customClass: customClass,
                    background: '#ffffff',
                    html: '<img width="100%" src="'+src+'"/>',
                    showCloseButton: true,
                    showConfirmButton: false,
                    // closeButtonHtml: "",
                })
            },
        }
    }
</script>

<style>

</style>